
import Component from "vue-class-component";
import Vue from "vue";
import {Prop} from "vue-property-decorator";
import {Work} from "@/data/data";

@Component({})
export default class WorkCard extends Vue {
  @Prop({}) item!: Work;

  detail(): void {
    this.$router.push('/detail/' + this.item.id);
  }

  down(e: Event): void {
    (e?.target as HTMLElement).parentElement?.style.setProperty("--tz", "-65px");
  }

  leave(e: Event): void {
    const parent = (e?.target as HTMLElement).parentElement;
    parent?.style.setProperty("--ty", "0");
    (e?.target as HTMLElement).style.setProperty("--ty", "0");
    parent?.style.setProperty("--tz", "0");
    (e?.target as HTMLElement).style.setProperty("--tz", "0");
    parent?.style.setProperty("--rx", "0");
    (e?.target as HTMLElement).style.setProperty("--rx", "0");
    parent?.style.setProperty("--ry", "0");
    (e?.target as HTMLElement).style.setProperty("--ry", "0");
  }

  move(e: MouseEvent): void {
    const parent = (e?.target as HTMLElement).parentElement;
    const self = (e?.target as HTMLElement).getBoundingClientRect();
    const dx = e?.offsetX - self.width / 2;
    const dy = e?.offsetY - self.height / 2;
    parent?.style.setProperty("--rx", `${(dy * 0.1) / -1}deg`);
    parent?.style.setProperty("--ry", `${(dx * 0.75) / 10}deg`);
  }

  up(e: Event): void {
    (e?.target as HTMLElement).parentElement?.style.setProperty("--tz", "-12px");
  }
}
