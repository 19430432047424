
import Vue from "vue";
import APlayer from 'aplayer';
import Component from "vue-class-component";

@Component({})
export default class MediaPlayer extends Vue {
  // noinspection JSUnusedGlobalSymbols
  mounted(): void {
    new APlayer({
      container: this.$refs['aplayer'],
      fixed: true,
      audio: [{
        name: '冰块转转转',
        artist: '阿尔菲的花匠',
        url: require(`@/assets/music/mind.mp3`),
        cover: 'http://p1.music.126.net/FK7X1fdFiTBANZXI3MYPEg==/109951166551651138.jpg'
      }]
    });
  }
}
