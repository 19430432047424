
import WorkDetail from "@/components/WorkDetail.vue";
import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {WorkDetail}
})
export default class Detail extends Vue {
  id: string | null = null

  // noinspection JSUnusedGlobalSymbols
  mounted(): void {
    this.id = this.$route.params['id'];
  }
}
