
import Vue from "vue";
import MediaPlayer from "@/components/MediaPlayer.vue";

export default Vue.extend({
  name: "App",
  components: {MediaPlayer},
  data: () => ({
    //
  }),

  methods: {
    home(): void {
      if (this.$route.path !== '/') {
        this.$router.push("/");
      }
    }
  }
});
